function getCoords(ev) {
    if(!ev.target){
        return
    }
    let br = ev.target.getBoundingClientRect()

    if(ev.hasOwnProperty("center")){
        return {
            evx: ev.center.x - br.left,
            evy: ev.center.y - br.top
        }
    }
    
    let evx = ev.clientX - br.left
    let evy = ev.clientY - br.top

    if(evx<0){evx = 0}
    if(evx>br.width){evx = br.width}
    if(evy<0){evy = 0}
    if(evy>br.height){evy = br.height}

    return {evx, evy}
}

function getCanvasSizes(canvas){
    let br = canvas.getBoundingClientRect()
    return{
        w: br.width,
        h: br.height
    }
}


export { getCoords, getCanvasSizes }