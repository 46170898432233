export function wifi_on(ctx) {
    ctx.beginPath();
	ctx.moveTo(116.180000, 147.120000);
	ctx.bezierCurveTo(116.180000, 151.400800, 114.479400, 155.506500, 111.452400, 158.533000);
	ctx.bezierCurveTo(108.425400, 161.559800, 104.320100, 163.260600, 100.039400, 163.260600);
	ctx.bezierCurveTo(91.125100, 163.260600, 83.898400, 156.033900, 83.898400, 147.119600);
	ctx.bezierCurveTo(83.898400, 142.838800, 85.599000, 138.733100, 88.626000, 135.706600);
	ctx.bezierCurveTo(91.653000, 132.679700, 95.758300, 130.979000, 100.039000, 130.979000);
	ctx.bezierCurveTo(104.319800, 130.979000, 108.425500, 132.679600, 111.452000, 135.706600);
	ctx.bezierCurveTo(114.478800, 138.733600, 116.179600, 142.838900, 116.179600, 147.119600);
	ctx.moveTo(99.880600, 97.308600);
	ctx.bezierCurveTo(88.374600, 97.351800, 76.878600, 101.207100, 67.532600, 108.860600);
	ctx.bezierCurveTo(66.672880, 109.571140, 66.154200, 110.612400, 66.104500, 111.726800);
	ctx.bezierCurveTo(66.055100, 112.840900, 66.479450, 113.924000, 67.272800, 114.708100);
	ctx.bezierCurveTo(69.696500, 117.120900, 73.096600, 120.481500, 75.419000, 122.776400);
	ctx.bezierCurveTo(76.829300, 124.170200, 79.053600, 124.305200, 80.622000, 123.092190);
	ctx.bezierCurveTo(91.903000, 114.308990, 107.939000, 114.256890, 119.286000, 122.960780);
	ctx.bezierCurveTo(120.860400, 124.161180, 123.080200, 124.011580, 124.478900, 122.610270);
	ctx.bezierCurveTo(126.797700, 120.295670, 130.191900, 116.896170, 132.617400, 114.466270);
	ctx.bezierCurveTo(133.408160, 113.674200, 133.826600, 112.583870, 133.769000, 111.465870);
	ctx.bezierCurveTo(133.711100, 110.347770, 133.182390, 109.306470, 132.314000, 108.599970);
	ctx.bezierCurveTo(122.902600, 101.029270, 111.385000, 97.264970, 99.880000, 97.307970);
	ctx.lineTo(99.880280, 97.308170);
	ctx.moveTo(78.021280, 125.348170);
	ctx.lineTo(78.005080, 125.364370);
	ctx.lineTo(78.021280, 125.348170);
	ctx.moveTo(99.888280, 36.748170);
	ctx.bezierCurveTo(72.661280, 36.850780, 45.473280, 46.583070, 24.077280, 65.919170);
	ctx.bezierCurveTo(23.277470, 66.649520, 22.812580, 67.676370, 22.791180, 68.759170);
	ctx.bezierCurveTo(22.769560, 69.842070, 23.193640, 70.886570, 23.963880, 71.648170);
	ctx.bezierCurveTo(26.496780, 74.159570, 30.141580, 77.763370, 32.609880, 80.203670);
	ctx.bezierCurveTo(34.100880, 81.677770, 36.482780, 81.733270, 38.040980, 80.330190);
	ctx.bezierCurveTo(72.990980, 49.099190, 126.547980, 48.942190, 161.690980, 80.007190);
	ctx.bezierCurveTo(163.246280, 81.393290, 165.610680, 81.334690, 167.095380, 79.872940);
	ctx.bezierCurveTo(169.629080, 77.383840, 173.415680, 73.655740, 176.032180, 71.079840);
	ctx.bezierCurveTo(176.810100, 70.313990, 177.237280, 69.260840, 177.212580, 68.169440);
	ctx.bezierCurveTo(177.187580, 67.078340, 176.713170, 66.045440, 175.901280, 65.315840);
	ctx.bezierCurveTo(154.355280, 46.163840, 127.105280, 36.646840, 99.892280, 36.747840);
	ctx.lineTo(99.891790, 36.747510);
	ctx.moveTo(35.310790, 82.867510);
	ctx.lineTo(35.166010, 83.004070);
	ctx.lineTo(35.310790, 82.867510);
	ctx.moveTo(99.912790, 67.091510);
	ctx.bezierCurveTo(80.547790, 67.164510, 61.214790, 73.955010, 45.840790, 87.446510);
	ctx.bezierCurveTo(45.024030, 88.171710, 44.543890, 89.202710, 44.514590, 90.294310);
	ctx.bezierCurveTo(44.485290, 91.386210, 44.909340, 92.441310, 45.685990, 93.209210);
	ctx.bezierCurveTo(48.252790, 95.758010, 51.957490, 99.420910, 54.447190, 101.883210);
	ctx.bezierCurveTo(55.917690, 103.337510, 58.260190, 103.413910, 59.822690, 102.059130);
	ctx.bezierCurveTo(82.741690, 82.293130, 117.144690, 82.171130, 140.209690, 101.779060);
	ctx.bezierCurveTo(141.778390, 103.120160, 144.114790, 103.028660, 145.573490, 101.568950);
	ctx.bezierCurveTo(148.063590, 99.085450, 151.771490, 95.375350, 154.342390, 92.803350);
	ctx.bezierCurveTo(155.116960, 92.028500, 155.535590, 90.967450, 155.498590, 89.872450);
	ctx.bezierCurveTo(155.461790, 88.777450, 154.972690, 87.746650, 154.147690, 87.025850);
	ctx.bezierCurveTo(138.664690, 73.660850, 119.275690, 67.018850, 99.912690, 67.092850);
	ctx.lineTo(99.912990, 67.090850);
	ctx.moveTo(57.118990, 104.524850);
	ctx.lineTo(57.086890, 104.549050);
	ctx.lineTo(57.118990, 104.524850);
	ctx.fill()
}

export function wifi_off(ctx){
    ctx.beginPath();
	ctx.moveTo(164.260000, 15.023000);
	ctx.lineTo(136.651000, 42.632000);
	ctx.bezierCurveTo(124.754000, 38.663700, 112.319000, 36.697000, 99.889000, 36.743300);
	ctx.bezierCurveTo(72.662000, 36.845910, 45.473000, 46.578900, 24.077000, 65.915300);
	ctx.bezierCurveTo(23.277190, 66.645650, 22.813200, 67.672300, 22.791800, 68.755100);
	ctx.bezierCurveTo(22.770180, 69.838000, 23.193480, 70.882100, 23.963700, 71.643800);
	ctx.bezierCurveTo(26.496600, 74.155200, 30.141900, 77.758100, 32.610200, 80.198500);
	ctx.bezierCurveTo(34.101200, 81.672600, 36.483600, 81.728500, 38.041800, 80.325450);
	ctx.bezierCurveTo(60.939800, 59.864450, 91.821800, 52.744450, 120.281800, 59.001450);
	ctx.lineTo(111.428300, 67.854950);
	ctx.bezierCurveTo(107.608400, 67.338980, 103.759500, 67.074600, 99.910300, 67.089330);
	ctx.lineTo(99.910300, 67.087377);
	ctx.bezierCurveTo(80.545300, 67.160377, 61.211300, 73.951177, 45.838300, 87.442377);
	ctx.bezierCurveTo(45.021540, 88.167577, 44.541500, 89.198377, 44.512100, 90.290077);
	ctx.bezierCurveTo(44.482800, 91.381977, 44.907320, 92.438177, 45.684000, 93.206077);
	ctx.bezierCurveTo(48.250800, 95.754877, 51.956000, 99.417577, 54.445700, 101.879877);
	ctx.bezierCurveTo(55.916200, 103.334177, 58.258200, 103.410377, 59.820700, 102.055657);
	ctx.bezierCurveTo(69.028500, 94.114557, 80.090700, 89.350657, 91.525700, 87.756657);
	ctx.lineTo(76.070700, 103.211657);
	ctx.bezierCurveTo(73.082100, 104.782857, 70.216100, 106.656057, 67.531600, 108.854257);
	ctx.bezierCurveTo(66.671880, 109.564797, 66.153500, 110.607057, 66.103900, 111.721457);
	ctx.bezierCurveTo(66.084399, 112.161237, 66.157973, 112.589047, 66.279680, 113.002657);
	ctx.lineTo(30.572680, 148.709657);
	ctx.lineTo(41.246680, 159.381657);
	ctx.lineTo(174.936680, 25.691657);
	ctx.lineTo(164.264680, 15.017657);
	ctx.moveTo(165.115460, 56.857000);
	ctx.lineTo(150.449460, 71.523000);
	ctx.bezierCurveTo(154.357360, 74.032800, 158.120560, 76.850000, 161.687460, 80.003500);
	ctx.bezierCurveTo(163.242760, 81.389600, 165.607060, 81.330500, 167.091760, 79.868740);
	ctx.bezierCurveTo(169.625460, 77.379640, 173.412760, 73.651640, 176.029260, 71.075740);
	ctx.bezierCurveTo(176.807180, 70.309890, 177.233660, 69.257040, 177.208960, 68.165540);
	ctx.bezierCurveTo(177.183960, 67.074440, 176.710320, 66.041640, 175.898460, 65.312040);
	ctx.bezierCurveTo(172.444960, 62.242240, 168.838360, 59.431340, 165.115460, 56.856940);
	ctx.moveTo(143.010460, 78.964000);
	ctx.lineTo(128.174460, 93.800000);
	ctx.bezierCurveTo(132.418560, 95.940400, 136.463760, 98.593900, 140.207460, 101.776600);
	ctx.bezierCurveTo(141.776160, 103.117700, 144.114060, 103.025400, 145.572660, 101.565670);
	ctx.bezierCurveTo(148.062760, 99.082170, 151.769260, 95.372070, 154.340260, 92.800070);
	ctx.bezierCurveTo(155.114830, 92.025220, 155.533560, 90.963370, 155.496460, 89.868470);
	ctx.bezierCurveTo(155.459660, 88.773470, 154.971820, 87.743670, 154.146860, 87.022770);
	ctx.bezierCurveTo(150.624460, 83.982070, 146.890960, 81.308570, 143.009860, 78.964170);
	ctx.moveTo(35.310460, 82.864400);
	ctx.lineTo(35.165930, 82.999170);
	ctx.lineTo(35.310460, 82.864400);
	ctx.moveTo(120.486460, 101.489400);
	ctx.lineTo(105.107460, 116.868400);
	ctx.bezierCurveTo(110.159760, 117.678340, 115.050460, 119.706600, 119.287460, 122.956300);
	ctx.bezierCurveTo(120.861860, 124.156700, 123.082060, 124.006000, 124.480860, 122.604740);
	ctx.bezierCurveTo(126.799660, 120.290140, 130.193960, 116.890140, 132.619560, 114.460240);
	ctx.bezierCurveTo(133.410320, 113.668170, 133.827560, 112.578240, 133.769960, 111.460240);
	ctx.bezierCurveTo(133.712060, 110.342140, 133.183320, 109.301540, 132.314860, 108.595040);
	ctx.bezierCurveTo(128.649960, 105.646940, 124.665260, 103.280740, 120.486860, 101.489540);
	ctx.moveTo(57.119460, 104.520600);
	ctx.lineTo(57.086256, 104.546000);
	ctx.lineTo(57.119460, 104.520600);
	ctx.moveTo(100.039460, 130.973600);
	ctx.bezierCurveTo(95.758360, 130.973600, 91.654360, 132.675300, 88.627460, 135.702100);
	ctx.bezierCurveTo(85.600460, 138.729000, 83.898960, 142.833400, 83.898960, 147.114100);
	ctx.bezierCurveTo(83.898960, 156.028400, 91.125260, 163.255100, 100.039960, 163.255100);
	ctx.bezierCurveTo(104.320960, 163.255100, 108.426960, 161.555400, 111.453960, 158.528500);
	ctx.bezierCurveTo(114.480960, 155.501700, 116.182460, 151.395300, 116.182460, 147.114500);
	ctx.bezierCurveTo(116.182460, 142.833500, 114.480760, 138.729400, 111.453960, 135.702500);
    ctx.bezierCurveTo(108.427160, 132.675600, 104.320760, 130.974000, 100.039960, 130.974000);
    ctx.fill()
}

export function lamp_profile(ctx){
    ctx.beginPath();
	ctx.moveTo(200.000000, -19.882000);
	ctx.bezierCurveTo(176.306000, -9.762000, 139.853000, -3.507000, 100.000000, -3.495000);
	ctx.bezierCurveTo(60.159000, -3.501340, 23.706000, -9.735300, 0.000000, -19.841000);
	ctx.lineTo(0.000000, 4.114000);
	ctx.bezierCurveTo(23.412000, 14.098000, 59.492000, 20.505000, 100.000000, 20.505000);
	ctx.bezierCurveTo(140.508000, 20.505053, 176.588000, 14.098400, 200.000000, 4.114000);
    ctx.stroke()
    ctx.fill()
}

export function lamp_lumi(ctx){
    ctx.beginPath();
	ctx.moveTo(0.000000, 4.113281);
	ctx.lineTo(0.000000, 200.000000);
	ctx.lineTo(200.000000, 200.000000);
	ctx.lineTo(200.000000, 4.113281);
	ctx.bezierCurveTo(176.588000, 14.097681, 140.508000, 20.505912, 100.000000, 20.505859);
	ctx.bezierCurveTo(59.492000, 20.505859, 23.412000, 14.097281, 0.000000, 4.113281);
    ctx.stroke()
    ctx.fill()
}

export function eye_open(ctx){
	ctx.beginPath();
	ctx.moveTo(0.000000, 0.000000);
	ctx.lineTo(0.000000, 200.000000);
	ctx.lineTo(200.000000, 200.000000);
	ctx.lineTo(200.000000, 0.000000);
	ctx.lineTo(0.000000, 0.000000);
	ctx.moveTo(178.600000, 99.061000);
	ctx.bezierCurveTo(178.600000, 99.061000, 136.466000, 116.045000, 120.594000, 118.852000);
	ctx.bezierCurveTo(85.545000, 125.050500, 64.013000, 119.106940, 46.176000, 113.039500);
	ctx.bezierCurveTo(38.986300, 110.593800, 32.334000, 111.150800, 32.334000, 111.150800);
	ctx.bezierCurveTo(32.334000, 111.150800, 48.683000, 65.994800, 99.197000, 63.679800);
	// ctx.bezierCurveTo(32.334000, 111.150800, 48.683000, 65.994800, 99.197000, 63.679800);
	ctx.bezierCurveTo(134.926000, 62.042300, 159.181000, 88.649800, 178.600000, 99.060800);
	// ctx.bezierCurveTo(134.926000, 62.042300, 159.181000, 88.649800, 178.600000, 99.060800);
    ctx.fill()
}

export function eye_reflection(ctx){
	ctx.beginPath();
	ctx.moveTo(121.080000, 80.940000);
	ctx.bezierCurveTo(122.023680, 87.232800, 120.772260, 95.482000, 120.772260, 95.482000);
	ctx.bezierCurveTo(120.772260, 95.482000, 133.767260, 89.457200, 133.767260, 89.457200);
	ctx.bezierCurveTo(133.767260, 89.457200, 134.582730, 85.551900, 132.785500, 79.685000);
	ctx.bezierCurveTo(131.717600, 76.199000, 130.338300, 72.281800, 129.264000, 70.694900);
	ctx.bezierCurveTo(124.168000, 67.857300, 118.893000, 67.080400, 112.993000, 65.234400);
	ctx.bezierCurveTo(115.132600, 68.766800, 119.578000, 73.153000, 121.080200, 80.940400);
	ctx.bezierCurveTo(121.080200, 80.940400, 121.080000, 80.940000, 121.080000, 80.940000);
    ctx.fill()
}

export function eye_closed(ctx){
	ctx.beginPath();
	ctx.moveTo(0.000000, 0.000000);
	ctx.lineTo(0.000000, 200.000000);
	ctx.lineTo(200.000000, 200.000000);
	ctx.lineTo(200.000000, 0.000000);
	ctx.lineTo(0.000000, 0.000000);
	ctx.moveTo(178.600000, 99.061000);
	ctx.bezierCurveTo(178.600000, 99.061000, 134.369000, 104.060100, 118.497000, 106.867100);
	ctx.bezierCurveTo(83.448000, 113.065600, 66.312000, 112.868900, 46.176000, 113.039500);
	ctx.bezierCurveTo(38.582000, 113.103800, 32.334000, 111.150800, 32.334000, 111.150800);
	ctx.bezierCurveTo(32.334000, 111.150800, 50.181000, 99.851800, 100.695000, 97.536800);
	ctx.bezierCurveTo(136.424000, 95.899300, 155.885000, 94.042800, 178.600000, 99.060600);
    ctx.fill()
}