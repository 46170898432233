import c1w from "./c1"
import c2w from "./c2"
import c3w from "./c3"
import c4w from "./c4"
document.addEventListener("readystatechange", ev => {
    if (document.readyState === "interactive") {
        canvasesInit()
    }
})

let c1 = null
let c2 = null
let c3 = null
let c4 = null
function canvasesInit(){
    c1 = document.getElementById("c1")
    c2 = document.getElementById("c2")
    c3 = document.getElementById("c3")
    c4 = document.getElementById("c4")
    c1w(c1)
    c2w(c2)
    c3w(c3)
    c4w(c4)
}