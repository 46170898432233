import Hammer from "hammerjs"
import { getCoords, getCanvasSizes } from "./common"
import { eye_open, eye_reflection, eye_closed } from "./icons"
import Throttler from "../utils/throttler";

export default function c1w(canvas) {

    const ctx = canvas.getContext('2d');
    let { w, h } = getCanvasSizes(canvas)
    canvas.width = w
    canvas.height = h


    let throttledDraw = new Throttler(draw, 20)
    canvas.addEventListener('mousemove',
        (ev) => { throttledDraw.queue(ev) }
    )

    let hammer = new Hammer(canvas)
    hammer.on("pan", (ev) => { throttledDraw.queue(ev) }
    )
    hammer.get("pan").set({
        direction: Hammer.DIRECTION_ALL,
        threshold: 1
    })

    hammer.on("press", (ev) => {
        blinkFrames = 7
        isLightOn = !isLightOn
        draw()
    })
    hammer.get("press").set({
        time: 10,
        threshold: 100
    })


    let blinkFrames
    let latestevx = 100
    let latestevy = 100
    let isLightOn = false

    function draw(ev) {

        ctx.fillStyle = "rgb(230,230,230)"
        ctx.fillRect(0, 0, w, h)

        let skinTone = "rgb(70,70,70)"
        if(isLightOn){
            skinTone = "rgb(140,140,140)"
        }

        if (blinkFrames > 0) {
            ctx.fillStyle = skinTone
            eye_closed(ctx)
            if (blinkFrames-- > 0) {
                requestAnimationFrame(draw)
            }
        } else {
            if (ev && typeof ev !== "number") {
                var { evx, evy } = getCoords(ev);
                latestevx = evx
                latestevy = evy
            } else {
                var evx = latestevx
                var evy = latestevy
            }

            let deltax = (evx - w / 2) / 10
            let deltay = (evy - h / 2) / 10

            // iris
            ctx.fillStyle = "rgb(150,150,150)"
            ctx.beginPath()
            ctx.arc((w / 2) + deltax, (h / 2) + deltay, 40, 0, 2 * Math.PI)
            ctx.fill()

            // pupil
            ctx.fillStyle = "rgb(20,20,20)"
            ctx.beginPath()
            ctx.arc((w / 2) + deltax * 2, (h / 2) + deltay * 2, 18, 0, 2 * Math.PI)
            ctx.fill()

            // reflection
            ctx.fillStyle = "rgb(255,255,255)"
            ctx.globalAlpha = 0.5
            eye_reflection(ctx)
            ctx.globalAlpha = 1

            // skin
            ctx.fillStyle = skinTone
            eye_open(ctx)
        }


        ctx.fillStyle = "white"
        let recth = 20
        ctx.fillRect(0,h-recth,w,recth)
        ctx.fillStyle = "black"
        ctx.font = "14px Verdana"
        ctx.fillText("Eye and gesture tracking", 5, h-5, w-10)
    }

    draw()

}
