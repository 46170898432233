import Hammer from "hammerjs"
import { getCoords, getCanvasSizes } from "./common"
import {lamp_profile, lamp_lumi} from "./icons"

export default function c3w(canvas) {

    const ctx = canvas.getContext('2d');
    let {w, h} = getCanvasSizes(canvas)
    canvas.width = w
    canvas.height = h

    let hammer = new Hammer(canvas)
    hammer.on("pan", (ev) => { draw(ev) }
    )
    hammer.get("pan").set({
        direction: Hammer.DIRECTION_ALL,
        threshold: 1
    })


    let pow = 255
    function draw(ev) {
        if(ev){
            var {evx, evy} = getCoords(ev);
        }else{
            var evx = 0
            var evy = 200
        }
        pow = 100 + 155 * evy/h
        if(pow<100){
            pow = 100
        }else if(pow>255){
            pow = 255
        }

        ctx.lineWidth = 2
        ctx.fillStyle = "black"
        ctx.fillRect(0 ,0,w,h)

        let gradient = ctx.createLinearGradient(w/2, 0, w/2, h)
        gradient.addColorStop(0, `rgb(${pow},${pow},${pow})`)
        let minpow = pow/5
        gradient.addColorStop(1, `rgb(${minpow},${minpow},${minpow})`)
        ctx.fillStyle = gradient
        lamp_lumi(ctx)

        ctx.fillStyle = "rgba(255,255,255,0.5)"
        let totrays = 10
        for(let i=0; i<totrays; i++){
            let _w = 5
            let _h = (pow/255) * (h/4)
            let _x = (w/(totrays) * i) + w/(totrays*2) - _w/2
            let _y = Math.sin((Math.PI*_x/w))*15 + 5
            ctx.fillRect(_x, _y, _w, _h)
        }

        ctx.fillStyle = "white"
        lamp_profile(ctx)
        
        
        let recth = 20
        ctx.fillRect(0,h-recth,w,recth)
        ctx.fillStyle = "black"
        ctx.font = "14px Verdana"
        ctx.fillText("100 to 2100 Lumen", 5, h-5, w-10)
    }

    draw()
}