import Hammer from "hammerjs"
import { getCoords, getCanvasSizes } from "./common"
import {wifi_on, wifi_off} from "./icons"

export default function c2w(canvas) {

    const ctx = canvas.getContext('2d');
    let {w, h} = getCanvasSizes(canvas)
    canvas.width = w
    canvas.height = h

    let hammer = new Hammer(canvas)
    hammer.on("tap", (ev) => { draw(ev) }
    )
    
    
    let wifion = true
    function draw(ev) {
        ctx.fillStyle = "rgb(240,240,240)"
        ctx.fillRect(0 ,0,w,h)
        wifion = !wifion
        
        ctx.save()
        
        ctx.scale(0.7,0.7)
        ctx.translate(40,30)
        if(wifion){
            ctx.fillStyle = "rgb(30, 70, 170)"
            wifi_on(ctx)
        }else{
            ctx.fillStyle = "black"
            wifi_off(ctx)
        }

        ctx.restore()

        ctx.fillStyle = "white"
        let recth = 20
        ctx.fillRect(0,h-recth,w,recth)
        ctx.fillStyle = "black"
        ctx.font = "14px Verdana"
        ctx.fillText("Offline or connected", 5, h-5, w-10)
    }

    draw()
}