import Hammer from "hammerjs"
import { getCoords, getCanvasSizes } from "./common"
import { lamp_profile, lamp_lumi } from "./icons"

export default function c4w(canvas) {

    const ctx = canvas.getContext('2d');
    let { w, h } = getCanvasSizes(canvas)
    canvas.width = w
    canvas.height = h

    let hammer = new Hammer(canvas)
    hammer.on("pan", (ev) => { draw(ev) }
    )
    hammer.get("pan").set({
        direction: Hammer.DIRECTION_ALL,
        threshold: 1
    })


    function draw(ev) {
        if(ev){
            var {evx, evy} = getCoords(ev);
        }else{
            var evx = 0
            var evy = 200
        }

        let idx = Math.floor(evy * kelvin_table.length / h)
        if (idx < 0) {
            idx = 0
        } else if (idx > kelvin_table.length - 1) {
            idx = kelvin_table.length - 1
        }
        let [r, g, b] = kelvin_table[idx]

        ctx.lineWidth = 2
        ctx.fillStyle = "black"
        ctx.fillRect(0, 0, w, h)

        let gradient = ctx.createLinearGradient(w / 2, 0, w / 2, h);
        gradient.addColorStop(0, `rgb(${r},${g},${b})`)
        gradient.addColorStop(1, `rgb(${r / 3},${g / 3},${b / 3})`)
        ctx.fillStyle = gradient
        lamp_lumi(ctx)
        
        ctx.fillStyle = "white"
        lamp_profile(ctx)
        
        let gradient2 = ctx.createLinearGradient(w / 2, 0, w / 2, h);

        [r, g, b] = kelvin_table[0]
        gradient2.addColorStop(0.3, `rgb(${r},${g},${b})`);
        [r, g, b] = kelvin_table[kelvin_table.length-1]
        gradient2.addColorStop(0.7, `rgb(${r},${g},${b})`)

        ctx.fillStyle = gradient2;
        ctx.fillRect(10, 40, 15, h-80);


        let recth = 20
        ctx.fillRect(0,h-recth,w,recth)
        ctx.fillStyle = "black"
        ctx.font = "14px Verdana"
        ctx.fillText("3000K to 6500K", 5, h-5, w-10)
    }

    //3000 to 6500
    var kelvin_table = [
        [255, 180, 107],
        [255, 184, 114],
        [255, 187, 120],
        [255, 190, 126],
        [255, 193, 132],
        [255, 196, 137],
        [255, 199, 143],
        [255, 201, 148],
        [255, 204, 153],
        [255, 206, 159],
        [255, 209, 163],
        [255, 211, 168],
        [255, 213, 173],
        [255, 215, 177],
        [255, 217, 182],
        [255, 219, 186],
        [255, 221, 190],
        [255, 223, 194],
        [255, 225, 198],
        [255, 227, 202],
        [255, 228, 206],
        [255, 230, 210],
        [255, 232, 213],
        [255, 233, 217],
        [255, 235, 220],
        [255, 236, 224],
        [255, 238, 227],
        [255, 239, 230],
        [255, 240, 233],
        [255, 242, 236],
        [255, 243, 239],
        [255, 244, 242],
        [255, 245, 245],
        [255, 246, 247],
        [255, 248, 251],
        [255, 249, 253],
    ]

    draw()

}